import React, { lazy } from "react";
import IconMenuClients from "../assets/icons/IconMenuClients.svg";
import IconMenuApiResources from "../assets/icons/IconMenuApiResources.svg";
import IconMenuIdentityResources from "../assets/icons/IconMenuIdentityResources.svg";
import IconMenuApiScopes from "../assets/icons/IconMenuApiScopes.svg";
import IconMenuAzureAd from "../assets/icons/IcconMenuAzureAdConfig.svg";
import IconMenuTenants from "../assets/icons/IconMenuTenants.svg";

import { LiMultiterm } from "lisecutilityfunctions/lib/utilitylib";
import { HealthCheck, Statistics, APITokens } from 'module-maintenance'

const ClientsRoot = lazy(() => import("../pages/clients/ClientsRoot"));
const ApiResourcesRoot = lazy(() => import("../pages/apiresources/ApiResourcesRoot"));
const IdentityResourcesRoot = lazy(() => import("../pages/identityresources/IdentityResourcesRoot"));
const AzureAdConfigurationRoot = lazy(() => import("../pages/azureadconfig/AzureAdConfigurationRoot"));
const ApiScopesRoot = lazy(() => import("../pages/apiScopes/ApiScopesRoot"));
const TenantsRoot = lazy(() => import("../pages/tenants/TenantsRoot"));

export const routes = [
	{
		path: "/clients",
		name: <LiMultiterm textId="99031108" textVal="Clients" />,
		component: ClientsRoot,
		children: [],
		icon: IconMenuClients,
		roles: [2, 48],
	},
	{
		path: "/apiResources",
		name: <LiMultiterm textId="99031109" textVal="API Resources" />,
		component: ApiResourcesRoot,
		children: [],
		icon: IconMenuApiResources,
		roles: [2, 48],
	},
	{
		path: "/apiScopes",
		name: <LiMultiterm textId="99031111" textVal="API scopes" />,
		component: ApiScopesRoot,
		children: [],
		icon: IconMenuApiScopes,
		roles: [2, 48],
	},
	{
		path: "/identityResources",
		name: <LiMultiterm textId="99031110" textVal="Identity Resources" />,
		component: IdentityResourcesRoot,
		children: [],
		icon: IconMenuIdentityResources,
		roles: [2, 48],
	},
	{
		path: "/azure-ad-config",
		name: <LiMultiterm textId="99031101" textVal="Azure AD Configuration" />,
		component: AzureAdConfigurationRoot,
		children: [],
		icon: IconMenuAzureAd,
		roles: [2, 48],
	},
	{
		path: "/tenants",
		name: <LiMultiterm textId="99031112" textVal="Tenants" />,
		component: TenantsRoot,
		children: [],
		icon: IconMenuTenants,
		roles: [2, 48],
	},
	{
		path: '/maintenance',
		name: <LiMultiterm textId="06007159" textVal="Maintenance" />,
		component: HealthCheck,
		icon: IconMenuTenants,
		routes: [
			{
				path: '/healthcheck',
				name: <LiMultiterm textId="99030737" textVal="Healthcheck" />,
				component: HealthCheck,
				icon: IconMenuTenants,
				roles: [2, 49],
			},
			{
				path: '/statistics',
				name: <LiMultiterm textId="6000160" textVal="Statistics" />,
				component: Statistics,
				icon: IconMenuTenants,
				roles: [2, 49],
			},
			{
				path: '/apitokens',
				name: <LiMultiterm textId="99030966" textVal="ApiTokens" />,
				component: APITokens,
				icon: IconMenuTenants,
				roles: [2, 49],
			},
		],
		roles: [2, 49],
	},

];
