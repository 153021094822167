// window.config = {
// 	'isOnAzureCloud': false,
// 	'authMethod': 'JWT',
// 	'servicenameSubstitution': {},
// 	'configURL': {
// 		'baseURL': "http://swpdmsrv4.lisec.internal/"
// 	}
// }

window.config = {
	isOnAzureCloud: true,
	authMethod: "OAUTH",
	servicenameSubstitution: {},
	configURL: {
		"baseURL": "https://atse-ngd-core1.lisec.internal:7445/",
	},
};
