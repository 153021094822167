import React, { useEffect } from "react";
import { Mainframe } from "application-base";
import { routes } from "./configs/route";
import "./configs/config_dev";
import axios from "axios";
import {
  setLocalStorageValue,
  getLocalStorageValue,
} from "lisecutilityfunctions/lib/commonfunction";
import './styles/global.css';

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  require("./configs/config_dev");
} else {
  require("./configs/config_prod");
}

const rewriteDefaultConfgiuration = () => {
  // console.log(process.env.PUBLIC_URL, "process.env.PUBLIC_URL")
  axios
    .get(`${process.env.PUBLIC_URL}/config_prod.json`)
    .then(function (response) {
      // console.log(response)
      if (response.data !== undefined) {
        const config = response.data.config;
        // if (!localStorage.getItem('core.app.basename')) {
        const responseURL = response.headers['x-basepath'] ? response.headers['x-basepath'] : ''
        const finalBaseName = responseURL
        // console.log(response.headers, responseURL, finalBaseName, "response")
        localStorage.setItem('core.app.basename', finalBaseName)
        // }
        if (config.baseURL && process.env.NODE_ENV !== "development") {
          window.config.configURL.baseURL = config.baseURL;
        }
        if (getLocalStorageValue("core.app.theme") === null) {
          setLocalStorageValue("core.app.theme", config.theme);
        }
      }
    })
    .catch(function (error) {
      console.error(error, "Error in reading config file");
    });
};
function App() {
  useEffect(() => {
    rewriteDefaultConfgiuration();
  }, []);

  return <Mainframe routes={routes} appName="IDS Configuration" />;
}

export default App;
